<template>
  <div>

    <div class="card" >
      <div class="card-body py-3 px-4 d-flex flex-column" style="gap: 1rem;">
        <!-- <div class="card">
          <div class="card-body p-3">
          </div>
        </div> -->
        <div>
          <div v-if="tournament.registration_event1 != null || tournament.registration_event2 != null">
            <div
              class="d-flex align-items-center"
              :class="
								(
									// tournament.match[0].group_member1_score < tournament.match[0].group_member2_score &&
									!tournament.match[0].is_registration_event1_win &&
									tournament.match[0].status == 'finished'
								) && 'loseOpacity'"
              style="gap: 1rem"
              v-if="tournament.registration_event1 != null"
            >
              <div class="flex-fill tournament-team-name">
								<div class="d-flex align-items-center">
									<p class="m-0 font-weight-bold text-nowrap">
										{{ tournament.registration_event1.team_name }}
									</p>
									<span class="badge badge-danger ml-1 mt-1" v-show="tournament.match[0].group_member1_disqualified == 1">Disqualified</span>
								</div>
                <p class="m-0 text-nowrap">
                  {{ tournament.registration_event1.user.name }}
                </p>
              </div>
              <img
                :src="tournament.registration_event1.url_image"
                style="width: 4rem"
                />
              <div class="scoreSeparator"></div>
							<div>
								<p class="m-0" v-if="tournament.match[0].event_category_sport_category != null && tournament.match[0].group_member1_score_timer != null">
									{{
										(tournament.match[0].group_member1_score_timer.hours() < 10 ? '0' + tournament.match[0].group_member1_score_timer.hours() : tournament.match[0].group_member1_score_timer.hours()) + ':' +
										(tournament.match[0].group_member1_score_timer.minutes() < 10 ? '0' + tournament.match[0].group_member1_score_timer.minutes() : tournament.match[0].group_member1_score_timer.minutes()) + ':' +
										(tournament.match[0].group_member1_score_timer.seconds() < 10 ? '0' + tournament.match[0].group_member1_score_timer.seconds() : tournament.match[0].group_member1_score_timer.seconds())
									}}
								</p>
								<h4 class="m-0" v-else>{{ tournament.match[0].group_member1_score }}</h4>
							</div>
              <!-- <div class="d-flex align-items-center">
              </div> -->
            </div>
            <div v-else>
              <h4 class="m-0">{{ tournament.from_tournament1 != null ? '-' : 'BYE' }}</h4>
            </div>
          </div>
          <div v-else>
            <h4 class="m-0">-</h4>
          </div>
        </div>

        <div class="py-2 border-top border-bottom d-flex justify-content-between align-items-center">
					<div class="d-flex flex-column">
            <span class="badge badge-primary d-flex align-self-start">
              {{ tournament.match[0].status_format }}
            </span>
<!--
            <small class="m-0">
            </small> -->
						<small class="m-0">
							{{ tournament.date_moment.format("DD MMMM YYYY HH:mm") }}
						</small>
					</div>

          <div class="d-flex ml-3" v-if="tournament.registration_event1.team_name != 'BYE' && tournament.registration_event2.team_name != 'BYE'" style="gap: .5rem;">
            <button class="btn btn-primary btn-sm" v-show="user != null && user.is_coordinator" @click="$emit('onMatchEditClicked', tournament.match[0])" style="" >
              Edit
            </button>
            <button class="btn btn-primary btn-sm" v-show="user != null && user.is_coordinator && tournament.registration_event1 != null" @click="$emit('onMatchClicked', tournament.match[0])" style="" >
              Game
            </button>
          </div>
        </div>

        <div>
          <div v-if="tournament.registration_event1 != null || tournament.registration_event2 != null">
            <div
              class="d-flex align-items-center"
              :class="
								(
									// tournament.match[0].group_member2_score < tournament.match[0].group_member1_score &&
									!tournament.match[0].is_registration_event2_win &&
									tournament.match[0].status == 'finished'
								) && 'loseOpacity'"
              style="gap: 1rem"
              v-if="tournament.registration_event2 != null"
            >
              <div class="flex-fill tournament-team-name">
								<div class="d-flex align-items-center">
									<p class="m-0 font-weight-bold text-nowrap">
										{{ tournament.registration_event2.team_name }}
									</p>
									<span class="badge badge-danger ml-1 mt-1" v-show="tournament.match[0].group_member2_disqualified == 1">Disqualified</span>
								</div>
                <p class="m-0 text-nowrap">
                  {{ tournament.registration_event2.user.name }}
                </p>
              </div>
              <img
                :src="tournament.registration_event2.url_image"
                style="width: 4rem"
                />
              <div class="scoreSeparator"></div>

							<div>
								<p class="m-0" v-if="tournament.match[0].event_category_sport_category != null && tournament.match[0].group_member2_score_timer != null">
									{{
										(tournament.match[0].group_member2_score_timer.hours() < 10 ? '0' + tournament.match[0].group_member2_score_timer.hours() : tournament.match[0].group_member2_score_timer.hours()) + ':' +
										(tournament.match[0].group_member2_score_timer.minutes() < 10 ? '0' + tournament.match[0].group_member2_score_timer.minutes() : tournament.match[0].group_member2_score_timer.minutes()) + ':' +
										(tournament.match[0].group_member2_score_timer.seconds() < 10 ? '0' + tournament.match[0].group_member2_score_timer.seconds() : tournament.match[0].group_member2_score_timer.seconds())
									}}
								</p>
								<h4 class="m-0" v-else>{{ tournament.match[0].group_member2_score }}</h4>
							</div>
            </div>
            <div v-else>
              <h4 class="m-0">{{ tournament.from_tournament2 != null ? '-' : 'BYE' }}</h4>
            </div>
          </div>
          <div v-else>
            <h4 class="m-0">-</h4>
          </div>
        </div>
        <!-- <div class="card">
          <div class="card-body p-3">
          </div>
        </div> -->
      </div>
    </div>

  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";

export default {
  props: ["tournament", "user",],
  components: {},
  data() {
    return {
      base: null,
    };
  },
  async created() {
    this.base = new Base();
  },
  methods: {},
};
</script>

<style lang="scss">
.tournament-team-name{
	width: 17rem;
}
.matchEventGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
.loseOpacity{
  opacity: 0.5;
}
.scoreSeparator{
  background-color: #dee2e6;
  width: 1px;
  height: 4rem;
}
</style>
